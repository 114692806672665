/*****************************
  Header
*****************************/

/* Header style 01 */
.header{
  background-color: $gray-9;
  .navbar {
    .navbar-nav {
      align-items: center;

      li {
        >a {
          align-items: center;
          display: flex;
          padding: 3px 0px;
          color: $gray-3;
          text-transform: capitalize;
          font-size: 14px;
          font-weight: 500;

          &:hover {
            color: $primary;
          }

          i {
            margin-left: 5px;
            font-size: 10px;
          }
        }
      }

      .nav-link {
        font-weight: 600;
        font-size: 14px;
        padding: 12px 8px 12px 0;
        color: $gray-3;
        text-transform: capitalize;
        position: relative;
        left: 0;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: $primary;
        }

        i {
          font-weight: bold;
        }

        &:hover {
          color: $primary;
        }
      }
    }

    .nav-item {
      margin-right: 20px;

      &:last-child {
        margin-right: 0px;
      }
    }

    .nav-item.megamenu-nav-area {
      position: inherit;
    }

    .nav-item.active {
      .nav-link {
        color: $primary;
      }
    }
  }

  .navbar-brand {
    padding: 0px;
    margin-right: 0;
    color: $white;
    text-align: left;
    flex: 0 0 230px;
    transition: none;
    img {
      height: 48px;
    }
  }

  .topbar {
    padding: 10px 0px;
    border-bottom: 1px solid rgba($white, 0.1);
    a {
      color: $white;
      &:hover {
        color: $primary;
      }
    }
  }

  .header-top {
    padding: 22px 0px;
  }

  .header-bottom {
    background-color: $white;
    position: relative;
    z-index: 2;
    .btn {
      margin: 5px;
    }
  }

}

.header.default {
  margin-bottom: -31px;
}

/* Header Sticky */
.header.is-sticky {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: $gray-9;
  box-shadow: $box-shadow;
  padding: 0px;
  .topbar {
    display: none;
  }
  .navbar {
    .navbar-brand-sticky {
       display: block;
    }
    .navbar-nav {
      .nav-link {
        color: $white;
      }
    }
    .nav-item.active {
      .nav-link {
        color: $primary;
      }
    }
  }
  .navbar-collapse {
    justify-content: flex-end !important;
  }
  .btn-primary {
    display: none !important;
  }
}

/* Header Default Sticky */
.header.is-sticky.default {
  .container {
    position: relative;    
  }
  .header-top {
    .navbar-expand-lg {
      display: none;
    }
  }
  .header-bottom {
    background-color: transparent;
    position: absolute;
    top: -70px;
    right: 15px;
    .col-lg-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-lg-3 {
      display: none !important;
    }
  }
}

/* Mega Menu */
.megamenu {
  padding: 20px 20px;
  width: 100%;
  border-radius: 0px;

  >div {
    >li {
      > ul {
        padding: 0;
        margin: 0;

        >li {
          list-style: none;

          >a {
            display: block;
            padding: 3px 20px;
            clear: both;
            font-weight: normal;
            line-height: 1.428571429;
            color: $gray-9;
            white-space: normal;

            &:hover {
              text-decoration: none;
              color: $gray-9;
              background-color: $gray-1;
            }

            &:focus {
              text-decoration: none;
              color: $gray-9;
              background-color: $gray-1;
            }
          }
        }
      }
    }
  }
}

.megamenu.disabled {
  >a {
    &:hover {
      color: $gray-1;
      text-decoration: none;
      background-color: transparent;
      background-image: none;
      cursor: not-allowed;
    }

    &:focus {
      color: $gray-1;
      text-decoration: none;
      background-color: transparent;
      background-image: none;
      cursor: not-allowed;
    }
  }
}

.header {
  .megamenu {
    .dropdown-item {
      padding: 0px;
      font-weight: 500;
    }
  }

  li {
    >.dropdown-item {
      &:focus {
        color: $primary;
        background: none;
      }

      &:hover {
        color: $primary;
        background: none;
      }
    }
  }

  .dropdown-item.active {
    background: none;
  }

  .dropdown-item {
    &:active {
      background: none;
    }

    &:focus {
      background: none;
    }

    &:hover {
      background: none;
    }
  }
}

.header .dropdown-toggle::after {
  content: none;
}

.header .navbar-collapse {
  align-items: inherit;
}

.header {
  .navbar {
    .dropdown-menu {
      padding: 0px 0px 15px 0px;
      z-index: 9999;
      border: none;

      a.dropdown-item {
        min-width: 180px;
      }
      li {
        a {
          i {
            margin-left: auto;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.navbar {
  .dropdown {
    >.dropdown-menu {
      li {
        >a {
          font-size: 13px;
          padding: 0px;
          color: $gray-3;
          position: relative;
          letter-spacing: 1px;

          &:hover {
            color: $primary;
          }

          span {
            padding: 8px 20px;
          }
        }

        &:last-child {
          border-bottom: none;

          > a span {
            &:before {
              content: none;
            }
          }
        }
      }

      .nav-title {
        color: $gray-7;
        padding: 8px 20px 0px;
      }
    }
  }

  .navbar-nav {
    .dropdown-menu {
      li.active {
        >a {
          span {
            color: $primary;

            &:before {
              transform: scaleX(1);
              transform-origin: left center;
            }
          }
        }
      }
    }
  }
}

/* Contact Info */
.main-contact {
  display: flex;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($white, 0.2);
    font-size: 17px;
    height: 50px;
    width: 50px;
    margin-right: 20px;
    border-radius: $border-radius;
    transition: all 0.3s ease-in-out;
  }
  .main-contact-info {
    h6 {
      font-size: 14px;
      margin-bottom: 0px;
    }
  }
  &:hover {
    i {
      color: $primary;
      background-color: rgba($primary, 0.2);
    }
  }
}

/* Header style 02 */
.header-style-02 {
  .navbar {
    .navbar-nav {
      .nav-link {
        color: $white;
      }
    }
  }
}

.header-style-02.is-sticky {
  .btn-outline-white {
    display: none !important;
  }
}

/* Header style 03 */
.header-transparent {
  position: absolute;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 3;
  .navbar {
    .navbar-nav {
      .nav-link {
        color: $white;
      }
    }
  }
}

.header-transparent.is-sticky {
  .btn-outline-white {
    display: none !important;
  }
}



/*****************************
  Responsive
*****************************/
@media (min-width:1200px) {
  .navbar-nav .mega-menu {
    position: static;
  }

  .header {
    .navbar {
      .dropdown-menu {
        margin: 0px;
        font-size: 14px;
        background-color: $white;
        border-radius: 0px;
        border: none;
        left: 100%;
        transform: translate3d(-50%, 10px, 0);
        transition: all 0.3s ease-in-out;
        transition-property: opacity, visibility, transform;
        transform-origin: top center;
        box-shadow: $box-shadow-lg;
        visibility: hidden;
        opacity: 0;
        display: block !important;

        .dropdown-submenu {
          .dropdown-menu {
            left: 100%;
            right: auto;
            transform: translate3d(0px, 10px, 0);
          }

          .dropdown-menu.left-side {
            right: 100%;
            left: auto;
            transform: translate3d(-15px, 10px, 0);
          }
        }
      }

      .dropdown-menu.megamenu {
        left: 50%;
      }

      .dropdown {
        &:hover {
          >.dropdown-menu {
            transform: translate3d(-50%, 0, 0);
            visibility: visible;
            opacity: 1;
            padding: 10px 0px;
          }
        }
      }

      li {
        &:hover {
          >ul.dropdown-menu {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .dropdown-submenu {
    position: relative;

    >.dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
    }
  }

  .header.default {
    .navbar {
      .dropdown {
        &:hover >  {
          .dropdown-menu.dropdown-menu-lg {
            transform: translate3d(0%, 0, 0);
          }
        }
      }
    }
  }

  .header.is-sticky {
    .navbar {
      .dropdown-menu.dropdown-menu-lg {
        transform: translate3d(-50%, -10px, 0);
      }
      .dropdown {
        &:hover > {
          .dropdown-menu.dropdown-menu-lg {
            transform: translate3d(-50%, 0, 0);
          }
        }
      }
    }
  }

}

@media (min-width:992px) {
  .header {
    .navbar {
      .dropdown-menu.dropdown-menu-md {
        min-width: 400px;
      }
      .dropdown-menu.dropdown-menu-lg {
        min-width: 600px;
      }
    }
    .navbar-expand-lg {
      padding: 0px;
    }
  }

  .header.default {
    .navbar-expand-lg {
      padding: 7px 30px;
    }

    .dropdown-menu.dropdown-menu-lg {
      min-width: 600px;
      left: 0;
      transform: translate3d(0%, -10px, 0);
    }

  }

  .header.is-sticky {
    .dropdown-menu.dropdown-menu-lg {
      transform: translate3d(-50%, -10px, 0);
    }
  }
  

 
}

@media (max-width:1600px) {
  .header  {
    .navbar-brand {
      flex: 0 0 170px;
    }
    .navbar {
      .nav-item {
        margin-right: 15px;
      }
    }
  }
  

  .navbar {
    .dropdown {
      >.dropdown-menu {
        li {
          >a {
            letter-spacing: 0;
          }

        }

      }

    }

  } 

}

@media (max-width:1366px) {
  .header  {
    .navbar-brand {
      flex: 0 0 170px;
    }
    .navbar {
      .nav-item {
        margin-right: 15px;
      }
    }  
  }
}


@media (max-width:1199px) {
  .header {
    .navbar-brand {
      flex: 0 0 200px;
    }

    .navbar {
      .nav-item {
        .shop-bg {
          background-image: inherit;
          border: none;
        }
      }
      .dropdown-menu {
        max-height: 350px;
        overflow-x: hidden;
        overflow-y: scroll;        
      }
    }
  }

  .navbar {
    .dropdown {
      >.dropdown-menu {
        li {
          >a {
            letter-spacing: 0;
          }
        }
      }
    }
  }

  .navbar .dropdown > .dropdown-menu li > a {
    letter-spacing: 0;
  }

}

@media (max-width:991px) {

  .header.default {
    margin-bottom: 0;
  }

  /* Header */
  .header {
    .navbar-collapse {
      position: absolute;
      top: 100%;
      z-index: 999;
      background: $white;
      width: 100%;
      left: 0;
      margin-left: 0px;
      box-shadow: $box-shadow;
    }

    .navbar {
      padding: 0px;
      .navbar-nav {
        align-items: initial;

        .nav-link {
          color: $gray-9;
          padding: 10px 20px;

          i {
            position: absolute;
            right: 20px;
          }
        }
      }

      .dropdown-menu {
        margin: 0px;
        font-size: 14px;
        border-radius: 0px;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: $gray-6;
        padding: 14px 10px;
        font-size: 13px;
      }

      .navbar-toggler {
        position: absolute;
        right: 15px;
        padding-right: 0;
        color: $white;
      }
    }

    .navbar-brand {
      img {
        height: 40px;
      }
    }      
    .header-bottom {
      .navbar {
        padding: 0px;
      }
    }

  }

  .header.is-sticky {
    .navbar {
      padding: 15px 0px;
    }
  }
  
  /* Header Style 01 */
  .header {
    .navbar {
      .navbar-nav {
        .nav-item {
          margin-right: 0;
          border-bottom: 1px solid $gray-6;        
        }
        .nav-link {
          color: $gray-9;
          padding: 12px;
        }
      }
    }
    .header-top {
      .navbar-toggler {
        color: $white;
        padding: 9px .75rem;
      }
    }    
  } 

  .header.is-sticky.default {
    .header-top {
      .navbar-expand-lg {
        display: block;
      }
    }    
    .header-bottom {
      position: inherit;
      top: 0;
      left: 0;
      .navbar {
        padding: 0px;
        .navbar-nav {
          .nav-link {
            color: $gray-7;
          }
        }
        .nav-item.active {
          .nav-link {
            color: $primary;
          }
        }
      }
    }
  }

  /* Header Style 02 */
  .header-transparent {
    position: inherit;
    background-color: $gray-9;    
  }

  .header-transparent.is-sticky {
    .navbar {
      padding: 0px;
      .navbar-nav {
        .nav-link {
          color: $gray-7;
        }
      }
    }
  }

  /* Header Style 03 */
  .header-style-02.is-sticky {
    .navbar {
      padding: 0px;
      .navbar-nav {
        .nav-link {
          color: $gray-7;
        }
      }
    }
  }    

}

@media (max-width:575px) {

  /* Header */
  .header {
    .navbar {
      .navbar-brand {
        flex: 0 0 160px;
        padding: 5px 0px;
      }
    }
  }

  .header.is-sticky {
    .navbar {
      padding: 10px 0px;
    }
  }

}


