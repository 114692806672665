/*****************************
  Layout
*****************************/

/* Section Title */
.section-title {
	margin-bottom: 50px;
		h2{
			color: $gray-7;
			margin-bottom: 30px;
		}
}

/* Client Logo */
.our-clients {
	.owl-item {
		text-align: center;
		img {
			filter: grayscale(100%);
			transition: all 0.3s ease-in-out;
		}
		&:hover {
			img {
				filter: inherit;
			}
		}
	}
}

/* Back To Top */
.back-to-top {
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		bottom: 50px;
		right: 40px;
		width: 40px;
		height: 40px;
		margin: 0px;
		color: $white;
		font-size: 16px;
		background: $primary;
		box-shadow: $box-shadow-lg;
		transition: all 0.3s ease-in-out;
		z-index: 999;
		&:hover {
			background: $gray-9;
			color: $white;
		}
		&:focus {
			background: $gray-9;
			color: $white;
		}
	}
}

/* Social-icon */
.social-icon {
	ul{
		 display: inline-block;
		 padding: 0;
		 margin: 0;
		li{
			    display: inline-block;
			    margin: 0px 15px;
			     &:first-child{
			    	margin-left: 0;
			    }
			    &:last-child{
			    	margin-right: 0;
			    }
			a{
		    color: $gray-7;
		    font-size: 14px;
		    &:hover{
		    	color: $primary;
		    }
			}
		}
	}
}

/* Social-icon */
.social-icon-round {
	ul{
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		li{
			    display: inline-block;
			    margin: 0px 8px;
			     &:first-child{
			    	margin-left: 0;
			    }
			    &:last-child{
			    	margin-right: 0;
			    }
			a{
				display: block;
		    color: $primary;
		    font-size: 16px;
		    width: 40px;
		    height: 40px;
		    line-height: 40px;
		    text-align: center;
		    border: 1px solid $primary;
		    transition: all 0.5s ease;
		    &:hover{
		    	color: $white;
		    	background: $primary;
		    }
			}
		}
	}
}

/* Social-icon-small */
.social-icon-round.icon-sm {
	ul{
		li{
			a{
		    font-size: 14px;
		    width: 30px;
		    height: 30px;
		    line-height: 30px;
			}
		}
	}
}

/* Social Bg Color */
.social-bg-hover {
	&:before {
		content: "";
		color: $white;
		width: 100%;
		height: 100%;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		transition: all 0.3s ease-in-out;
	}
	position: relative;
	color: $white;
	&:hover {
		color: $white;
		&:before {
			background-color: rgba(black, 0.1);
		}
	}
	span {
		position: relative;
	}
}

.facebook-bg {
	background-color: #445c8e;
}

.twitter-bg {
	background-color: #43afe9;
}

.google-bg {
	background-color: #dc0510;
}

.instagram-bg{
	background-color: #DD2A7B;
}

.linkedin-bg {
	background-color: #007eb3;
}

.pinterest-bg {
	background-color: #E60023;
}

.bg-blue {
	background-color: #2772fb;
}

.bg-purple {
	background-color: #9a27fb;
}

.bg-green {
	background-color: #13da91;
}

.bg-gold {
	background-color: #fbae27;
}

.bg-pink {
	background-color: #fb279f;
}

.bg-orange {
	background-color: #fb6d27;
}


/* Maintenance */
.maintenance-content {
	padding-top: 100px;
}

/* Coming soon */
.coming-soon-top{
	position: absolute;
	left: 0;
	right: 0;
 }

.coming-soon-section {
	padding-top: 100px;
	.coming-soon-icon {
		i {
			font-size: 110px;
			color: $gray-2;
		}
	}
}

/* Form */
form {
	.form-group {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.form-row {
		margin-right: -10px;
		margin-left: -10px;
		>.col {
			padding-right: 10px;
			padding-left: 10px;
		}
		>[class*=col-] {
			padding-right: 10px;
			padding-left: 10px;
		}
	}
}

.form-control {
	padding: 14px 20px;
	height: 61px;
	font-size: 16px;
	border-color: $border-color;
	transition: all 0.3s ease-in-out;
	color: $gray-7;
	border-radius: 0px;
	&::placeholder {
		color: $gray-7;
	}
	&:focus {
		box-shadow: none;
		border-color: $primary;
	}
}
.custom-control-input {
	&:checked {
		~ {
			.custom-control-label {
				&:before {
					background: $primary;
					border-color: $primary;
				}
			}
		}
	}
}

.custom-control-input:focus~.custom-control-label::before {
	box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
	border-color: $primary;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
	background-color: $primary;
	border-color: $primary;
}

/* Dark Form */
.dark-form {
	.form-control {
		border-color: rgba($white, 0.2);
		background-color: rgba($white, 0.1);
		color: rgba($white, 0.4);
		&:focus {
			border-color: $primary;
			color: rgba($white, 0.4);
		}
		&::placeholder {
			color: rgba($white, 0.4);
		}
	}
}

/* Light Form */
.light-form {
	.form-control {
		border-color: $gray-1;
		background-color: $gray-1;
		color: $gray-7;
		&:focus {
			border-color: $primary;
			color: $gray-7;
		}
		&::placeholder {
			color: $gray-7;
		}
	}
}

/* Table */
.table-striped {
	thead {
		tr {
			background-color: $primary;
			color: $white;
			font-size: 16px;
			th {
				font-weight: normal;
				border: none;
			}
		}
	}
	tbody {
		tr {
			td {
				color: $gray-3;
				border-top-color: $border-color;
			}
			&:nth-of-type(odd) {
				background-color: transparent;
			}
			&:nth-of-type(even) {
				background-color: $gray-1;
			}
		}
	}
}

/* Select */
.select2-container {
	.select2-selection--single {
		height: 61px;
		border-color: $border-color;
		border-radius: inherit;
		.select2-selection__rendered {
			color: $gray-7;
			line-height: 61px;
			font-size: 16px;
		}
		.select2-selection__rendered {
			padding: 0px 20px;
		}

		.select2-selection__arrow {
			height: 61px;
			right: 20px;
			width: 20px;
		}
	}
}

.find-Course{
	.select2-container{
       z-index: 9;
	}
 }

 .input-group.date {
 	.input-group-append{
 	  .input-group-text{
        padding: 21px;
        border-radius: inherit;
 	 }
   }
 }


/* Input Size */
.form-control-sm {
  height: 44px;
}

.form-control-md {
  height: 52px;
}

.form-control-lg {
  height: 58px;
}

.form-control-xl {
  height: 62px;
  font-size: 1.45rem;
}

/* form ratings */
.ratings {
	border: none;
	float: left;
}

.ratings > input {
	display: none;
}

.ratings > label:before {
	margin: 5px;
	font-size: 1.25em;
	font-family: "Font Awesome 5 Free";
	display: inline-block;
	content: "\f005";
	font-weight: 900;
}

.ratings > .half:before {
	content: "\f089";
	position: absolute;
}

.ratings > label {
	color: #ddd;
	float: right;
}

.ratings > input:checked ~ label,
.ratings:not(:checked) > label:hover,
.ratings:not(:checked) > label:hover ~ label {
	color: #FFD700;
}

.ratings > input:checked + label:hover,
.ratings > input:checked ~ label:hover,
.ratings > label:hover ~ input:checked ~ label,
.ratings > input:checked ~ label:hover ~ label {
	color: #FFED85;
}

/* Map */
iframe.map {
	width: 100%;
}

/* contact info style 01 */
.contact-info-style-01 {
	padding: 90px 40px 60px 40px;
	img {
		height: 46px;
		margin-bottom: 65px;
	}
	h6 {
		font-weight: normal;
		margin-bottom: 40px;
	}
	h2 {
		font-size: 36px;
		margin-bottom: 20px;
		font-weight: normal;
	}
	p {
		margin-bottom: 40px;
	}
	.btn-white {
		background: rgba($white, 0.1);
		border: none;
		color: $white;
		&:hover {
			background: rgba($gray-9, 0.1);
			color: $gray-9;
		}
	}
}

.map-contact {
	.contact-info-style-01 {
		position: absolute;
		top: 72px;
	}
}

#particles-js {
	position: relative;
	z-index: 0;
	canvas.particles-js-canvas-el {
		position: absolute;
		top: 0;
		z-index: -1;
	}
}

/* custom checkbox */
.custom-control {
	min-height: 25px;
    padding-left: 30px;
}

.custom-control-input {
	width: 25px;
    height: 25px;
}

.custom-control-label::before {
	width: 20px;
	height: 20px;
	border: none;
	left: -30px;
	top: 0px;
	border: 2px solid $border-color-2;
}

.custom-control-label::after {
	width: 20px;
	height: 20px;
	left: -30px;
	top: 0px;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}

.our-clients .owl-carousel img {
	padding: 30px 40px;
	background: $white;
	filter: grayscale(100%);
	transition: all 0.3s ease-in-out;
}

.our-clients .owl-carousel img:hover {
	filter: grayscale(0);
}

.our-clients .owl-carousel .owl-dots {
	margin-top: 0;
	margin-bottom: 0px;
}

/* Home 04 */
.track-left {
	img {
		position: relative;
		right: -120px;
		bottom: -31px;
	}

}

.track-right {
	img {
		position: relative;
		left: -90px;
		bottom: -61px;
	}

}

.transport-service-info{
 margin-right: 20px;
}


/*****************************
  Responsive
*****************************/
@media (min-width: 1200px) {

	.container {
		max-width: 1400px;
	}

}

@media (max-width: 1199px) {
  .social-icon {
  ul{
     li{
      margin: 0px 10px;
     }
    }
  }

  /* Home 04 */
  .track-left {
	  img {
		right: -84px;
		bottom: -20px;
	  }

	}

	.track-right {
		img {
			left: -50px;
			bottom: -40px;
		}

	}

}


@media (max-width:991px) {

	/* Section Title */
	.section-title {
		margin-bottom: 30px;
	}

	.display-4 {
		font-size: 2.5rem !important;
	}

  /* Home 04 */
  .track-left {
	  img {
		right: -104px;
		bottom: -25px;
	  }
	}

	.track-right img {
		left: -80px;
		bottom: -66px;
	}

}

@media only screen and (min-width:768px) and (max-width:991px) {

	.container {
		max-width: 96%;
	}

}

@media (max-width:767px) {

	/* Section Title */
	.section-title {
		margin-bottom: 20px;
	}

	/* Stretch BG */
	.stretch-bg {
		border-radius: 30px 0 30px 0;
	}
	.stretch-bg.stretch-left {
		&:before {
			border-radius: 30px 0 30px 0;
		}

	}

	.display-4 {
		font-size: 2rem !important;
	}

	/* Home 04 */
	.track-left {
		margin-right: 130px;
		img {
			right: -99px;
			bottom: -23px;
		}
	}

	.track-right {
		margin-left: 70px;
		img {
			left: -65px;
			bottom: -55px;
		}
	}




}


@media (max-width:575px) {
	.form-inline {
		.btn {
			position: inherit;
		}
	}

	/* contact info style 01 */
	.contact-info-style-01 {
		padding: 40px 20px 40px 20px;
		img {
			margin-bottom: 40px;
		}
		h6 {
			margin-bottom: 20px;
		}
		h2 {
			font-size: 30px;
		}
		.btn {
			padding: 18px 20px;
		}
	}

	/* Home 04 */
	.track-left {
		margin-right: 70px;
		img {
			right: -70px;
			bottom: -12px;
		}
	}

	.track-right {
		margin-left: 70px;
		img {
			right: -70px;
			bottom: -24px;
		}
	}

	.our-clients {
	  .owl-carousel {
		 img{
            padding: 20px;
	    }
	  }
	}


}
