/*****************************
  Button
*****************************/

button {
	outline: medium none !important;
	color: $primary;
}

/* Btn */
.btn {
	display: inline-block;
	font-size: 16px;
	line-height: 1;
	padding: 22px 40px;
	font-weight: 600;
	border-radius: 0px;
	transition: all 0.3s ease-in-out;
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none;
				outline: none;
			}
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none;
					outline: none;
					color: $gray-9;
				}
			}
		}
	}
	&:hover {
		box-shadow: none;
		outline: none;
	}
	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
	}
	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
	}
}
.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}
	}
	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: $primary;
	}
}
.btn-primary {
	background: $primary;
	border-color: $primary;
	color: $white;
	&:hover {
		background: $gray-7;
		border-color: $gray-7;
	  	color: $white;
		&:not(:disabled) {
			&:not(.disabled).active {
				background: $gray-7;
				border-color: $gray-7;
			}
			&:not(.disabled) {
				&:active {
					background: $gray-7;
					border-color: $gray-7;
				}
			}
		}
	}
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
	background: $gray-7;
	border-color: $gray-7;
	color: $primary;
}

.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle {
	background: $primary;
	border-color: $primary;
}

.btn-primary.focus, 
.btn-primary:focus {
	background: $primary;
	border-color: $primary;	
}

.btn {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				color: $white;
			}
		}
	}
	+ {
		.btn {
			margin-left: 3px;
		}
	}
}


.btn-dark {
	background-color: $gray-7;
	color: $white;
	border-color: $gray-7;

	&:hover {
		background-color: $primary;
		border-color: $primary;
	}

	&:focus {
		background-color: $primary;
		border-color: $primary;
	}

	&:not(:disabled) {
		&:not(.disabled).active {
			background-color: $primary;
			border-color: $primary;
		}

		&:not(.disabled) {
			&:active {
				background-color: $primary;
				border-color: $primary;
			}

		}

	}

}

.btn-dark.focus {
	background-color: $primary;
	border-color: $primary;
}

.show {
	>.btn-dark.dropdown-toggle {
		background-color: $primary;
		border-color: $primary;
	}

}

/* Link */
.btn-link {
	color: $primary;
	padding: 0;
	&:hover {
		color: $gray-7;
		text-decoration: none;
		outline: none;
	}
	&:focus {
		color: $gray-7;
		text-decoration: none;
		outline: none;
	}
}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
	color: $gray-7;
}

.btn-white {
	background: $white;
	border-color: $white;
	color: $gray-7;
	&:hover {
		background: $gray-7;
		border-color: $gray-7;
		color: $white;
	}
	&:active {
		color: $white;
		background: $gray-7;
		border-color: $gray-7;
	}
	&:focus {
		color: $white;
		background: $gray-7;
		border-color: $gray-7;
	}
}

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
	color: $white;
	background: $gray-7;
    border-color: $gray-7;
}

.btn-light {
	background-color: $gray-1;
	border-color: $gray-1;
	&:hover {
		background: $gray-7;
		border-color: $gray-7;
		color: $white;
	}
	&:active {
		background-color: $gray-1;
		border-color: $gray-1;
		color: $gray-7;
	}
	&:focus {
		background-color: $gray-1;
		border-color: $gray-1;
		color: $gray-7;
	}
}


a.text-dark {
	color: $gray-7 !important;
}

a.text-dark:focus, 
a.text-dark:hover {
	color: $primary !important;
	transition: all 0.3s ease-in-out;
}

/* btn outline */
.btn-outline-white {
	color: $white;
	border-color: $white;
	&:hover {
		background-color: $white;
		border-color: $white;
		color: $gray-7;
	}
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none;
			}
			background-color: $white;
			border-color: $white;
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none;
				}
				background-color: $white;
				border-color: $white;
			}
		}
	}
}
.btn-outline-white.btn {
	&:not(:disabled) {
		&:not(.disabled) {
			&:active {
				&:focus {
					color: $gray-7;
				}
			}
		}
	}
}
.show {
	>.btn-outline-white.dropdown-toggle {
		&:focus {
			box-shadow: none;
		}
		background-color: $white;
		border-color: $white;
	}
}



/* Button Size */
.btn.btn-sm {
    padding: 18px 30px;
    font-size: 14px;
    line-height: 1;
}
.btn.btn-md {
    padding: 22px 40px;
    line-height: 1;
	font-size: 16px;
}
.btn.btn-lg {
    padding: 26px 50px;
    font-size: 18px;
    line-height: 1;
}
.btn.btn-xl {
    padding: 30px 60px;
    font-size: 20px;
    line-height: 1;
}