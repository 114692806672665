/*****************************
  Banner
*****************************/

/* slider 01 */
.slider-01 {
	.swiper-slide {
		height: 850px;
    }
    .banner-content {
        ul {
            li {
                margin-right: 18px;
                a {
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    color: $white;
                    &:hover {
                        color: $primary;
                    }
                    i {
                        font-size: 14px;
                        color: $primary;
                        margin-right: 8px;
                    }
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }       
    }
    .slide-01 {
        h6 {
            font-weight: normal;
            border-left: 3px solid $primary;
            padding-left: 20px;
        } 
    }
}

/* slider 02 */
.slider-02 {
    .swiper-slide {
        height: 774px;    
        padding-bottom: 6rem;
        h6 {
            font-weight: normal;
        }
    }
}

/* slider 03 */
.slider-03 {
    height: 1046px;
    display: flex;
    align-items: center;
    &:after {
        z-index: 0;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        content: "";
        background: linear-gradient(to top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.9) 100%);
        bottom: auto;
        height: 20%;
        transition: all 0.3s ease-in-out;
    }
    .banner-inner-title {
        display: flex;
        margin-bottom: 70px;
        h1 {
            font-size: 54px;
            line-height: 1;
            font-weight: 500;
        }
        .typer {
            background-color: $primary;
            color: $white;
            font-size: 48px;
            line-height: 1;
            padding: 25px 50px;
            min-width: 350px;
            position: relative;
            height: 98px;
            &:before {
                position: absolute;
                content: "";
                width: 30px;
                height: 21px;
                left: -30px;
                border-top: 25px solid rgba(239, 49, 57, 0);
                border-right: 14px solid $primary;
                top: 15px;
            }
        }
        .cursor {
            display: none;
        }
    }
    ul {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        li {
            margin: 0px 8px;
            padding: 0px 8px;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 4px;
                right: -8px;
                width: 2px;
                height: 17px;
                display: inline-block;
                background-color: $white;
            }
            &:last-child {
                &::before {
                    content: none;
                }
            }
            a {
                font-size: 20px;
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

/* slider 04 */
.slider-04 {
    height: 780px;
    .banner-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translate(0%, -50%);
        h1 {
            font-size: 70px;
            line-height: 82px;
        }
        h6 {
            line-height: 34px;
        }
    }
}


/* Bullet */
.swiper-pagination {
	.swiper-pagination-bullet {
		background-color: $gray-2;
		width: 88px;
		height: 5px;
		border-radius: 0;
        opacity: 1;
        outline: none;
	}
	.swiper-pagination-bullet-active {
		background-color: $primary;
	}
}
.swiper-pagination.swiper-pagination-left {
	text-align: left;
	margin: 0 auto;
	right: 0;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

/* Arrow */
.swiper-button-next {
	right: 53px;
    background-image: inherit !important;
    outline: none;
    width: 50px;
    height: 50px;
	i {
		font-size: 20px;
		color: rgba($white,0.5);
		width: 50px;
		height: 50px;
		line-height: 50px;
        text-align: center;
        outline: none;
		transition: all 0.3s ease-in-out;
	}
	&:hover {
		i {
			color: $white;
		}
	}
}
.swiper-button-prev {
    left: 53px;
    background-image: inherit !important;
    outline: none;
    width: 50px;
    height: 50px;
	i {
		font-size: 20px;
		color: rgba($white,0.5);
		width: 50px;
		height: 50px;
		line-height: 50px;
        text-align: center;
        outline: none;
		transition: all 0.3s ease-in-out;
	}
	&:hover {
		i {
			color: $white;
		}
	}
}

/*****************************
  Responsive
*****************************/

@media (min-width: 576px) {
    .swiper-pagination.swiper-pagination-left {
        max-width: 510px;
    }
}

@media (min-width: 768px) {
    .swiper-pagination.swiper-pagination-left {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .swiper-pagination.swiper-pagination-left {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .swiper-pagination.swiper-pagination-left {
        max-width: 1270px;
    }
}

@media (max-width: 1199px) {
    /* slider 01 */
    .slider-01 {
        .banner-content {
            h1 {
                font-size: 64px;
                line-height: 76px;
            }
        }
        .swiper-button-prev {
            display: none;
        }
        
        .swiper-button-next {
            display: none;
        }

    }   

    /* slider 04 */
    .slider-04 {
        .banner-content {
            h1 {
                font-size: 48px;
                line-height: 52px;
            }
        }
    }
    
    

}

@media (max-width: 991px) {

    /* slider 01 */
    .slider-01 {
        .banner-content {
            ul {
                li {
                    a {
                        font-size: 20px;
                        i {
                            font-size: 12px;
                        }
                    }
                }
            }
            h1 {
                font-size: 50px;
                line-height: 60px;
            }
        }
        .swiper-slide {
            height: 650px;
        }
    }

    .video-style-01{
        &:before {
            width: 150px;
            height: 182px;
        }
        a {
            font-size: 30px;
            i {
                font-size: 40px;
            }
        }
    }

    /* slider 02 */
    .slider-02 {
        .swiper-slide {
            height: 650px;
            h1 {
                font-size: 50px;
                line-height: 60px;
            }
        }
    }

    /* slider 03 */
    .slider-03 {
        height: 650px;
        .banner-inner-title {
            margin-bottom: 40px;
            h1 {
                font-size: 46px;
            }
            .typer {
                font-size: 38px;
                padding: 20px 30px;
                min-width: 250px;
                height: 78px;
            }
        }
        ul {
            margin-bottom: 40px;
        }
    }

    /* slider 04 */
    .slider-04 {
        height: 650px;
    }


}

@media (max-width: 767px) {
    /* slider 01 */
    .slider-01 {
        .swiper-slide {
            height: 500px;
        }
    }

    /* slider 02 */
    .slider-02 {
        .swiper-slide {
            height: 500px;
            padding-bottom: 3rem;
        }
    }

    /* slider 03 */
    .slider-03 {
        height: 500px;
        h1 {
            font-size: 40px;
        }
        h2 {
            display: none;
        }
        ul {
            li {
                a {
                    font-size: 18px;
                }
            }
        }
    }

    /* slider 04 */
    .slider-04 {
        height: 500px;
    }

}


@media (max-width: 575px) {
    /* slider 01 */
    .slider-01 {
        .banner-content {
            h1 {
                font-size: 32px;
                line-height: 42px;
            }
            ul {
                li {
                    margin-right: 10px;
                    display: inline-block;
                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    /* slider 02 */
    .slider-02 {
        .swiper-slide {
            height: 400px;
            h1 {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }

    /* slider 03 */
    .slider-03 {
        height: 400px;

        .banner-inner-title {
            margin-bottom: 20px;
            h1 {
                font-size: 34px;
            }
        }

        ul {
            margin-bottom: 20px;
            display: inherit;
            li {
                margin: 0px 6px;
                padding: 0px 6px;
                display: inline-block;
                a {
                    font-size: 16px;
                }
            }
                
        }
    }

    
    /* slider 04 */
    .slider-04 {
        height: 400px;
        .banner-content {
            h1 {
                font-size: 32px;
                line-height: 42px;
            }
            h6 {
                line-height: 26px;
                font-weight: normal;
            }
        }
    }

}

