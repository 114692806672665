/*****************************
    Footer
*****************************/
ul.footer-contact {
    li {
        display: flex;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        .footer-contact-icon {
            font-size: 36px;
            color: $white;
            margin-right: 15px;
            opacity: 0.3;
        }
        .footer-contact-info {
            color: $white;
            span {
                color: $primary;
            }
            p {
                opacity: 0.6;
            }
        }
    }
}

.footer-newsletter {
    margin-bottom: 70px;
    h2 {
        font-size: 36px;;
    }
    p{
        color: $white;
        opacity: 0.6;
        margin-bottom: 20px;
    }
    .form-inline {
        position: relative;
        .form-group {
            width: 100%;
            .form-control {
                width: 100%;
                padding-right: 210px;
            }
        }
        .btn-primary {
            position: absolute;
            right: 0px;
            top: 0px;
            &:hover {
                background-color: $white;
                border-color: $white;
                color: $primary;
            }
        }
    }    
}

.useful-links {
    ul {
        li {
            a {
                color: $white;
                line-height: 30px;
                opacity: 0.6;
                &:hover {
                    color: $primary;
                    opacity: 1;
                }
            }
        }
    }
}

.call-center {
    h2 {
        color: $white;
        font-size: 41px;
        margin-bottom: 0px;
        opacity: 0.6;
    }
    h3 {
        color: $white;
        font-size: 24px;
        opacity: 0.6;
    }
}

.footer-social-icon {
    margin-top: 100px;
    p {
        color: $white;
        font-size: 15px;
        opacity: 0.6;
    }

    ul {
        display: flex;
        margin-top: 20px;
        li {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 24px;
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}


.copyright {
    padding: 90px 0px 45px 0px;
    .footer-brand {
        img {
            height: 46px;
        }
    }
    a {
        color: $white;
        &:hover {
            color: $primary;
        }
    }
}

/*************************
       Responsive
*************************/

@media (max-width: 1199px) {
    .footer-newsletter {
        margin-bottom: 30px;
        .form-inline {
            .btn-primary {
                position: inherit;
                margin-top: 20px;
            }
        }
    }

    .call-center {
        h2 {
            font-size: 34px;
        }

        h3 {
            font-size: 20px;
        }
    }

    .footer-social-icon {
        margin-top: 40px;
    }

}


@media (max-width: 767px) {
    .copyright {
        padding: 60px 0px 35px 0px;
    }
}

@media (max-width: 575px) {
    .footer-social-icon {
        margin-top: 40px;
    }
    
    .copyright {
        padding: 40px 0px 20px 0px;
    }

}
