/*****************************
  Helper Classes
*****************************/

/* Color */
.text-primary {
  color: $primary !important;
}

.text-white {
  color: $white;
}

.text-dark {
  color: $gray-7 !important;
}

.text-light {
  color: $gray-3 !important;
}

.text-muted {
  color: #999999 !important;
}

.text-body {
  color: $gray-3 !important;
}

/* Background */
.bg-primary {
  background-color: $primary !important;
}

.bg-dark {
  background-color: $gray-9 !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-light {
  background-color: $gray-1 !important;
}

/* Page section padding */
.space-ptb {
  padding: 100px 0;
}

.space-pt {
  padding-top: 100px;
}

.space-pb {
  padding-bottom:100px;
}

.space-sm-ptb {
  padding: 70px 0;
}

.space-sm-pt {
  padding-top: 70px;
}

.space-sm-pb {
 padding-bottom:70px;
}

.h-100vh {
  height: 100vh !important;
}

.mr-xl-n6{
 margin-right: -5rem!important;
}

/* Half Overlay */
.half-overlay {
	&:before {
		content: "";
		background: $gray-9;
		position: absolute;
		top: 0;
		width: 40%;
		height: 100%;
	}
}
.half-overlay.left-position {
	&:before {
		left: 0;
	}
}
.half-overlay.right-position {
	&:before {
		right: 0;
	}
}


.bg-overlay-dark-left-100 {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    background-color: $gray-9;
    width: 100%;
    height: 100%;
  }

}

.bg-overlay-light-left-100 {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    background-color: $gray-1;
    width: 100%;
    height: 100%;
  }

}

.bg-overlay-right-100 {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: -100%;
    background-color: $primary;
    width: 100%;
    height: 100%;
  }

}

/* Img Holder */
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-no-cover {
  background-size: inherit;
}

.bg-contain {
  background-size: contain;
}
.bg-position-right{
  background-position: center right;
}


/* Background overlay */
.bg-overlay-dark-10 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-dark-20 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-dark-30 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-dark-40 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-dark-50 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-dark-60 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-dark-70 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-dark-80 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.bg-overlay-dark-90 {
  position: relative;
  z-index: 1;
  &:before {
    background: rgba($gray-9, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

/* Overlay */
.bg-blue-overlay{
  &:before {
    background: rgba(39, 114, 251, 0.8);

  }
}

.bg-purple-overlay{
  &:before {
    background: rgba(154, 39, 251, 0.8);
  }
}

.bg-green-overlay{
  &:before {
    background: rgba(19, 218, 145, 0.8);
  }
}

.bg-gold-overlay{
  &:before {
    background: rgba(251, 174, 39, 0.8);
  }
}

.bg-pink-overlay{
  &:before {
    background: rgba(251, 39, 159, 0.8);
  }
}

.bg-orange-overlay{
  &:before {
    background: rgba(251, 109, 39, 0.8);
  }
}

/* Avatar */
.avatar {
  min-width: 50px;
  width: 50px;
  display: inline-block;
}

.avatar.avatar-sm {
  min-width: 20px;
  width: 20px;
}

.avatar.avatar-md {
  min-width: 80px;
  width: 80px;
}

.avatar.avatar-lg {
  min-width:90px;
  width:90px;
}


/* Box Shadow */
.box-shadow {
  box-shadow: $box-shadow;
}

.box-shadow-lg {
  box-shadow: $box-shadow-lg;
}

.box-shadow-flat {
  box-shadow: $box-shadow-flat;
}

/* Overflow */
.overflowx-h{
  overflow-x:hidden;
}


/* Z index */
.z-index-1 {
  z-index: 1;
}

.z-index-9 {
  z-index: 9;
}

.z-index-99 {
  z-index: 99;
}

.z-index-999 {
  z-index: 999;
}

/* Opacity */
.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.grayscale, .img-grayscale {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
}

.grayscale:hover {
    filter: inherit;
}


/* Modal Dialog */
.modal-dialog {
  .login-social-media {
    .btn {
      padding: 10px 12px;
    }
  }
}

/* is-sticky */
.is-sticky {
    position: sticky;
    top: 140px;
}

.border-bottom {
  border-color: $border-color !important;
}

/*****************************
  Responsive
*****************************/
@media (min-width:576px) {
  /* Margin */
  .log-mt-sm-n4 {
    margin-top: -4rem !important;
  }

  .log-mt-sm-n5 {
    margin-top: -5rem !important;
  }

  .log-mt-sm-n6 {
    margin-top: -6rem !important;
  }

  .log-mt-sm-n7 {
    margin-top: -7rem !important;
  }

  .log-mt-sm-n8 {
    margin-top: -8rem !important;
  }

  .log-mt-sm-n9 {
    margin-top: -9rem !important;
  }

  .log-mt-sm-n10 {
    margin-top: -10rem !important;
  }

  .log-mr-sm-n4 {
    margin-right: -4rem !important;
  }

  .log-mr-sm-n5 {
    margin-right: -5rem !important;
  }

  .log-mr-sm-n6 {
    margin-right: -6rem !important;
  }

  .log-mr-sm-n7 {
    margin-right: -7rem !important;
  }

  .log-mr-sm-n8 {
    margin-right: -8rem !important;
  }

  .log-mr-sm-n9 {
    margin-right: -9rem !important;
  }

  .log-mr-sm-n10 {
    margin-right: -10rem !important;
  }

  .log-ml-sm-n4 {
    margin-left: -4rem !important;
  }

  .log-ml-sm-n5 {
    margin-left: -5rem !important;
  }

  .log-ml-sm-n6 {
    margin-left: -6rem !important;
  }

  .log-ml-sm-n7 {
    margin-left: -7rem !important;
  }

  .log-ml-sm-n8 {
    margin-left: -8rem !important;
  }

  .log-ml-sm-n9 {
    margin-left: -9rem !important;
  }

  .log-ml-sm-n10 {
    margin-left: -10rem !important;
  }

}

@media (min-width:768px) {
  /* Margin */
  .log-mt-md-n4 {
    margin-top: -4rem !important;
  }

  .log-mt-md-n5 {
    margin-top: -5rem !important;
  }

  .log-mt-md-n6 {
    margin-top: -6rem !important;
  }

  .log-mt-md-n7 {
    margin-top: -7rem !important;
  }

  .log-mt-md-n8 {
    margin-top: -8rem !important;
  }

  .log-mt-md-n9 {
    margin-top: -9rem !important;
  }

  .log-mt-md-n10 {
    margin-top: -10rem !important;
  }

  .log-mr-md-n4 {
    margin-right: -4rem !important;
  }

  .log-mr-md-n5 {
    margin-right: -5rem !important;
  }

  .log-mr-md-n6 {
    margin-right: -6rem !important;
  }

  .log-mr-md-n7 {
    margin-right: -7rem !important;
  }

  .log-mr-md-n8 {
    margin-right: -8rem !important;
  }

  .log-mr-md-n9 {
    margin-right: -9rem !important;
  }

  .log-mr-md-n10 {
    margin-right: -10rem !important;
  }

  .log-ml-md-n4 {
    margin-left: -4rem !important;
  }

  .log-ml-md-n5 {
    margin-left: -5rem !important;
  }

  .log-ml-md-n6 {
    margin-left: -6rem !important;
  }

  .log-ml-md-n7 {
    margin-left: -7rem !important;
  }

  .log-ml-md-n8 {
    margin-left: -8rem !important;
  }

  .log-ml-md-n9 {
    margin-left: -9rem !important;
  }

  .log-ml-md-n10 {
    margin-left: -10rem !important;
  }

}

@media (min-width:992px) {
  /* Margin */
  .log-mt-lg-n4 {
    margin-top: -4rem !important;
  }

  .log-mt-lg-n5 {
    margin-top: -5rem !important;
  }

  .log-mt-lg-n6 {
    margin-top: -6rem !important;
  }

  .log-mt-lg-n7 {
    margin-top: -7rem !important;
  }

  .log-mt-lg-n8 {
    margin-top: -8rem !important;
  }

  .log-mt-lg-n9 {
    margin-top: -9rem !important;
  }

  .log-mt-lg-n10 {
    margin-top: -10rem !important;
  }

  .log-mr-lg-n4 {
    margin-right: -4rem !important;
  }

  .log-mr-lg-n5 {
    margin-right: -5rem !important;
  }

  .log-mr-lg-n6 {
    margin-right: -6rem !important;
  }

  .log-mr-lg-n7 {
    margin-right: -7rem !important;
  }

  .log-mr-lg-n8 {
    margin-right: -8rem !important;
  }

  .log-mr-lg-n9 {
    margin-right: -9rem !important;
  }

  .log-mr-lg-n10 {
    margin-right: -10rem !important;
  }

  .log-ml-lg-n4 {
    margin-left: -4rem !important;
  }

  .log-ml-lg-n5 {
    margin-left: -5rem !important;
  }

  .log-ml-lg-n6 {
    margin-left: -6rem !important;
  }

  .log-ml-lg-n7 {
    margin-left: -7rem !important;
  }

  .log-ml-lg-n8 {
    margin-left: -8rem !important;
  }

  .log-ml-lg-n9 {
    margin-left: -9rem !important;
  }

  .log-ml-lg-n10 {
    margin-left: -10rem !important;
  }

}

@media (min-width:1200px) {
  /* Margin */
  .log-mt-xl-n4 {
    margin-top: -4rem !important;
  }

  .log-mt-xl-n5 {
    margin-top: -5rem !important;
  }

  .log-mt-xl-n6 {
    margin-top: -6rem !important;
  }

  .log-mt-xl-n7 {
    margin-top: -7rem !important;
  }

  .log-mt-xl-n8 {
    margin-top: -8rem !important;
  }

  .log-mt-xl-n9 {
    margin-top: -9rem !important;
  }

  .log-mt-xl-n10 {
    margin-top: -10rem !important;
  }

  .log-mr-xl-n4 {
    margin-right: -4rem !important;
  }

  .log-mr-xl-n5 {
    margin-right: -5rem !important;
  }

  .log-mr-xl-n6 {
    margin-right: -6rem !important;
  }

  .log-mr-xl-n7 {
    margin-right: -7rem !important;
  }

  .log-mr-xl-n8 {
    margin-right: -8rem !important;
  }

  .log-mr-xl-n9 {
    margin-right: -9rem !important;
  }

  .log-mr-xl-n10 {
    margin-right: -10rem !important;
  }

  .log-ml-xl-n4 {
    margin-left: -4rem !important;
  }

  .log-ml-xl-n5 {
    margin-left: -5rem !important;
  }

  .log-ml-xl-n6 {
    margin-left: -6rem !important;
  }

  .log-ml-xl-n7 {
    margin-left: -7rem !important;
  }

  .log-ml-xl-n8 {
    margin-left: -8rem !important;
  }

  .log-ml-xl-n9 {
    margin-left: -9rem !important;
  }

  .log-ml-xl-n10 {
    margin-left: -10rem !important;
  }

}

@media (max-width:1199px) {

	/* Page Section Padding */
	.space-ptb {
		padding: 70px 0;
	}

	.space-pt {
		padding-top: 70px;
	}

	.space-pb {
		padding-bottom:70px;
  }

  .space-sm-ptb {
    padding: 60px 0;
  }

  .space-sm-pt {
    padding-top: 60px;
  }

  .space-sm-pb {
   padding-bottom: 60px;
  }

  .mr-xl-n6{
   margin-right: 0rem!important;
  }

}

@media (max-width:991px) { 

  /* Page Section Padding */
	.space-ptb {
		padding: 60px 0;
	}

	.space-pt {
		padding-top: 60px;
	}

	.space-pb {
		padding-bottom: 60px;
  }

  .space-sm-ptb {
    padding: 50px 0;
  }

  .space-sm-pt {
    padding-top: 50px;
  }

  .space-sm-pb {
   padding-bottom: 50px;
  }

  /* Half Overlay */
  .half-overlay:before {
    content: none;
  }

  /* Border */
  .border-lg-none {
    border: none !important;
  }

  /* Overlay */
  .bg-overlay-left-100:before,
  .bg-overlay-right-100:before {
    display: none;
  }

  /* box shadow */
  .box-shadow-flat {
    box-shadow: 15px 17px 0px 0px $primary;
  }

}

@media (max-width:767px) {

  /* Page Section Padding */
	.space-ptb {
		padding: 50px 0;
	}

	.space-pt {
		padding-top: 50px;
	}

	.space-pb {
		padding-bottom: 50px;
  }

  .space-sm-ptb {
    padding: 40px 0;
  }

  .space-sm-pt {
    padding-top: 40px;
  }

  .space-sm-pb {
   padding-bottom: 40px;
  }

	.container {
		max-width: 100%;
  }

  /* Border */
  .border-md-none {
    border: none !important;
  }

    /* box shadow */
    .box-shadow-flat {
      box-shadow: 10px 12px 0px 0px $primary;
    }

  /* Request a quote */
  .request-a-quote{
    background-image: inherit !important;
  }

}

@media (max-width:575px) {

  /* Page Section Padding */
  .space-ptb {
		padding: 40px 0;
	}

	.space-pt {
		padding-top: 40px;
	}

	.space-pb {
		padding-bottom: 40px;
	}

  /* Border */
  .border-sm-none {
    border: none !important;
  }

}
