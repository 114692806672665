/*****************************
    Service
*****************************/
/* services style 01 */
.services-style-01 {
    .services-info {
        display: flex;
        background-color: $gray-9;
        color: $white;
        padding: 25px;
        .services-step {
            font-size: 28px;
            font-weight: bold;
            margin-right: 20px;
        }
        .services-content {
            h4 {
                line-height: 1;
                font-weight: 500;
                a {
                    color: $white;
                    &:hover {
                        color: $primary;
                    }
                }
            }
            p {
                opacity: 0.6;
                margin-bottom: 0px;
            }
        }
    }
}


.transport-service-info {
    .position-absolute {
       top: 0; 
       height: 100%;
       width: 100%;
       padding: 50px;
    }
    h4 {
        font-weight: normal;
        font-size: 28px;
        line-height: 36px;
    }
    .display-4 {
        font-size: 48px;
        font-weight: bold;
        position: absolute;
        bottom: 30px;
    }
}

/* Vertical Menu */
.vertical-menu {
    li {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            display: block;
            color: $gray-9;
            padding: 22px 40px;
            font-size: 16px;
            font-weight: 600;
            background-color: $gray-1;
             i{
                float: right;

             }
            &:hover {
                background-color: $primary;
                color: $white;
            }
        }
        a.active {
            background-color: $primary;
            color: $white;
        }
    }
}


@media (max-width: 575px) {
    .transport-service-info {
        .position-absolute {
            padding: 20px;
        }
        h4 {
            font-size: 22px;
            line-height: 30px;
        }
        .display-4 {
            font-size: 34px;
            bottom: 10px;
        }
    }
}