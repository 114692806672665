/*****************************
  Feature Info
*****************************/

/* Feature Info Style 01  */
.feature-info-border {
  [class*="col-"] {
    padding: 40px;
    border-right: 1px solid rgba($gray-9, 0.1);
    border-bottom: 1px solid rgba($gray-9, 0.1);
  }

  [class*="col-"]:nth-child(2n+0) {
    border-right: none;
  }

  [class*="col-"]:nth-child(3n+0) {
    border-bottom: none;
  }

  [class*="col-"]:nth-child(4n+0) {
    border-bottom: none;
  }

}

.feature-info {
  display: flex;
  i {
    color: $primary;
    margin-right: 28px;
  }
  .feature-info-title {
    margin-bottom: 0px;
  }
}

/* Feature Info Style 02 */
.feature-info-style-02 {
  background-color: $white;
  padding: 40px 35px 60px 35px;
  box-shadow: $box-shadow-lg;
  height: 100%;
  i {
    color: $primary;
  }
  .feature-info-title {
    margin: 30px 0px;
  }
  p {
    margin-bottom: 0;
    color: rgba($gray-7, 0.6);
  }
}

.feature-info-main-title {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-color: $primary;
  padding: 40px 35px 60px 35px;
  height: 100%;
  .feature-bg-icon {
    color: rgba($white, 0.2);
    position: absolute;
    right: -10px;
    bottom: -10px;
    z-index: 0;
  }
  a {
    margin-top: auto;
    position: relative;
    z-index: 1;
  }
    .feature-info-title {
      color: $white;
      margin-bottom: 30px;
    }

}

/* Feature Info Style 03 */
.feature-info-style-03 {
  text-align: center;
  position: relative;
  .feature-info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-1;
    height: 138px;
    width: 138px;
    border-radius: $border-radius;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
    &:after {
      content: "";
      background: url('../images/feature-info/arrow.png');
      width: 189px;
      height: 16px;
      position: absolute;
      top: 25%;
      left: 82%;
    }
  }
}

.feature-info-style-03.arrow-hide {
  .feature-info-icon{
    &:after {
      content: none;
    }
  }
}

/* Feature Info Style 04 */
.feature-info-style-04  {
  display: flex;
  align-items: center;
  padding-bottom: 70px;
  .step-icon {
    margin-right: 25px;
    position: relative;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray-1;
      color: $gray-5;
      width: 144px;
      height: 144px;
      border-radius: $border-radius;
    }
  }
  .step-info {
    p {
      margin-bottom: 0px;
    }
  }
}


/* Step Style 01 */
.step-style-01 {
    color: $white;
  .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    height: 100px;
    width: 100px;
    font-weight: bold;
    font-size: 46px;
    line-height: 1;
    border-radius: $border-radius;
  }
  .step-title {
    margin: 20px 0px;
    color: $white;
  }
  p {
    color: rgba($white, 0.6);
    margin-bottom: 30px;
  }
  .list {
    li {
      color: rgba($white, 0.6);
    }
  }
}

/* Step Style 02 */
.step-style-02 {
  display: flex;
  align-items: center;
  padding-bottom: 70px;
  .step-icon {
    margin-right: 25px;
    position: relative;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray-1;
      color: $gray-5;
      width: 144px;
      height: 144px;
      border-radius: $border-radius;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary;
      color: $white;
      font-size: 21px;
      font-weight: 600;
      height: 42px;
      width: 42px;
      position: absolute;
      top: -16px;
      left: 16px;
      border-radius: $border-radius;
    }
    &:after {
      content: "";
      background: $gray-1;
      width: 2px;
      height: 100%;
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 100%;
    }
  }
  .step-info {
    p {
      margin-bottom: 0px;
    }
  }
  &:last-child {
    padding-bottom: 0;
    .step-icon {
      &:after {
        content: none;
      }
    }
  }
}

/* Step Style 03 */
.step-style-03 {
  margin-bottom: 30px;
  display: flex;
  .step-number {
    position: relative;
    &:after {
      content: "";
      background: $gray-1;
      width: 2px;
      height: 100%;
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 30%;
  }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $gray-1;
      width: 72px;
      height: 72px;
      font-size: 24px;
      font-weight: bold;
      color: $gray-7;
      border-radius: $border-radius;
      transition: all 0.3s ease-in-out;
      position: relative;
      z-index: 1;
      &:after {
        left: 99%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(241, 241, 241, 0);
        border-left-color: $gray-1;
        border-width: 4px;
        margin-top: -4px;
        transition: all 0.3s ease-in-out;
      }
      &:before {
        left: 99%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(221, 221, 221, 0);
        border-left-color: $gray-1;
        border-width: 4px;
        margin-top: -4px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  &:last-child {
    .step-number {
      &:after {
        content: none;
      }
    }
  }
  .step-info {
    margin: 22px 0 0 20px;
    p {
      margin-bottom: 0px;
    }
  }
  &:hover {
    .step-number {
      span {
        background-color: $primary;
        color: $white;
        &:after {
          border-left-color: $primary;
        }
        &:before {
          border-left-color: $primary;
        }
      }
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
}

/* light style */
.bg-light {
  .step-style-03 {
    .step-number {
      span {
        background: $white;
        &:before {
          border-left-color: $white;
        }
        &:after {
          border-left-color: $white;
        }
      }
    }
    .step-number {
      &:after {
        background:$white;
      }
    }
    &:hover {
      .step-number {
        span {
          background-color: $primary;
          color: $white;
          &:after {
            border-left-color: $primary;
          }
          &:before {
            border-left-color: $primary;
          }
        }
      }
    }
  }
}



@media (max-width: 1199px) {
  /* Feature Info Style 01 */
  .feature-info-border {
    [class*="col-"] {
      padding: 20px;
    }
  }

  /* Feature Info Style 03 */
  .feature-info-style-03 {
    .feature-info-icon {
      &:after {
      left: 78%;
      background-size: 84%;
      background-repeat: no-repeat;
      }
    }
  }
}

@media (max-width: 991px) {
  /* Feature Info Style 03 */
  .feature-info-style-03 {
    .feature-info-icon {
      &:after {
      top: 20%;
      left: 88%;
      background-size: 44%;
      }
    }
  }
}

@media (max-width: 767px) {
  /* Feature Info Style 02 */
  .feature-info-main-title {
    padding: 30px;
    .feature-info-title {
      margin-bottom: 20px;
    }
  }

  .feature-info-style-02 {
    padding: 30px;
    .feature-info-title {
      margin: 20px 0px;
    }
  }

  /* Feature Info Style 03 */
  .feature-info-style-03 {
    .feature-info-icon {
      margin-bottom: 15px;
      &:after {
        content: none;
      }
    }
  }

  /* Step Style 01 */
  .step-style-01 {
    p {
      margin-bottom: 20px;
    }
  }

}

@media (max-width: 575px) {
    /* Feature Info Style 01 */
  .feature-info-border [class*="col-"] {
    border-right: none;
  }
  .feature-info-border [class*="col-"]:nth-child(3n+0) {
    border-bottom: 1px solid rgba($gray-9, 0.1);
  }

  /* Feature Info Style 03 */
  .feature-info-style-03 {
    .feature-info-icon {
      &:after {
        content: none;
      }
    }
  }

  /* Step Style 02 */
  .step-style-02 {
    padding-bottom: 50px;
    display: inherit;
    .step-icon {
      margin: 0px 0px 20px 0px;
      &:after {
        content: none;
      }
    }
  }

  /* Step Style 03 */
  .step-style-03 {
    margin-bottom: 20px;
    .step-number {
      &:after {
        content: none;
      }
    }
  }

}
