/*

Template: Transpo - Transport & Logistics HTML Template
Version: 1.0.0
Author: potenzaglobalsolutions
Design and Developed by: potenzaglobalsolutions.com

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template.

*/

/*================================================
[  Table of contents  ]
================================================

 :: Typography
 :: Helper Classes
 :: Shortcodes
    - Button
    - Accordion
    - shuffle
    - List
    - Feature info
    - Gallery
    - Testimonial
    - Countdown
    - Owl carousel
    - Progress Bar
    - Pricing
    - Range Slider
    - Datetimepicker
    - Counter
    - Select
    - Service
    - Team
    - Timeline
    - Video
 :: Header
   - Header style 01
   - Header style 02
   - Header style 03
 :: Slider
   - Slider 01
   - Slider 02
   - Slider 03
   - Slider 04
 :: Layout
   - Section Title
   - Client Logo
 :: Blog
   - Blog
 :: Not Found
 :: Footer

======================================
[ End table content ]
======================================*/

// Core files
@import "variables";
@import "typography";
@import "helpers";

// Shortcodes
@import "shortcodes/button";
@import "shortcodes/accordion";
@import "shortcodes/list";
@import "shortcodes/feature-info";
@import "shortcodes/gallery";
@import "shortcodes/testimonial";
@import "shortcodes/countdown";
@import "shortcodes/owl-carousel";
@import "shortcodes/progress-bar";
@import "shortcodes/range-slider";
@import "shortcodes/datetimepicker";
@import "shortcodes/select";
@import "shortcodes/service";
@import "shortcodes/shuffle";
@import "shortcodes/video";
@import "shortcodes/pricing";
@import "shortcodes/team";
@import "shortcodes/counter";
@import "shortcodes/timeline";

// Structure
 @import "header";
 @import "banner";
 @import "layout";

// Pages
@import "blog";
@import "error";
@import "footer";
