/*****************************
  Not Found
*****************************/

.error-404{
	h2 {
	   font-size: 200px	;
	   line-height: 1;
	   color: $primary;
	   margin-bottom: 10px;
	}
}

@media (max-width:767px) {

.error-404{
	h2 {
	   font-size: 130px	;
	}
  }
}

@media (max-width:575px) {

.error-404{
	h2 {
	   font-size: 80px	;
	}
  }

}
