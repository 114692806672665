/*****************************
  Typography
*****************************/

body {
	font-family: $font-base;
	font-weight: 500;
	font-style: normal;
	font-size: 14px;
	line-height: 24px;
	color: $body-color;
	overflow-x: hidden;
}

a {
	outline: medium none !important;
	color: $primary;
	transition: all 0.3s ease-in-out;
	&:focus {
		color: $gray-7;
		text-decoration: none !important;
	}
	&:hover {
		color: $gray-7;
		text-decoration: none !important;
	}
}

i {
	line-height: 1;
}

hr {
	border-color: $border-color !important;
}

input {
	outline: medium none !important;
	color: $primary;
}

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
	font-family: $font-hedding;
	font-weight: 600;
	color: $gray-7;
	margin-top: 0px;
	letter-spacing: -1px;
	a {
		color: inherit;
	}
}

label {
	font-weight: normal;
}

.h1,
h1 {
	font-size: 76px;
	line-height: 86px;
}

.h2,
h2 {
	font-size: 42px;
	line-height: 52px;
}

.h3,
h3 {
	font-size: 28px;
	line-height: 38px;
}

.h4,
h4 {
	font-size: 22px;
	line-height: 32px;
}

.h5,
h5 {
	font-size: 20px;
	line-height: 30px;
}

.h6,
h6 {
	font-size: 18px;
	line-height: 28px;
}

.lead {
	font-size: 16px !important;
	line-height: 26px;
}

small,
.small {
	font-size: 12px;
	font-weight: 500;
}

iframe {
	width: 100%;
}


*::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

/* Flaticon */
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
	margin-left: 0;
	font-size: inherit;
}

/* Inner header */
.inner-header {
	padding: 230px 0 80px;

}
.breadcrumb {
	background: transparent;
	margin-bottom: 0;
	padding: 0;
	.breadcrumb-item {
		font-size: 13px;
		font-weight: 600;
		padding-left: 10px;
	}
	.breadcrumb-item+.breadcrumb-item {
		&:before {
			content: "\f105";
			font-family: "Font Awesome 5 free";
			padding-right: 10px;
		}
	}
}
.inner-title {
	font-size: 50px;
	font-weight: 700;
	line-height: 50px;
}

.inner-subtitle {
	font-size: 20px;
}


// pagination
.pagination {
	.page-item {
		.page-link {
			padding: 18px 28px;
			color: $gray-3;
			border-color: $border-color;

			&:focus {
				box-shadow: none;
			}

		}

	}

	.page-item.active {
		.page-link {
			background: $primary;
			border-color: $primary;
			color: $white;
		}

	}

}




// blockquote
.blockquote {
	font-size: 16px;
	border-left: 10px solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
}

.blockquote-quote {
	position: relative;
	font-size: 18px;
	border-left: 0 solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
	z-index: 1;

	p {
		position: relative;
	}

	i {
		position: absolute;
		z-index: 0;
		top: 20px;
		left: 0;
		font-size: 60px;
		line-height: 60px;
		color: $gray-2;
	}

}

/*****************************
  Responsive
*****************************/

@media (max-width:991px) {
	.h1,
	h1 {
		font-size: 66px;
		line-height: 76px;
	}

	.h2,
	h2 {
		font-size: 32px;
		line-height: 42px;
	}

	.h3,
	h3 {
		font-size: 24px;
		line-height: 38px;
	}

	.h4,
	h4 {
		font-size: 20px;
		line-height: 32px;
	}

	.h5,
	h5 {
		font-size: 18px;
		line-height: 30px;
	}

	.h6,
	h6 {
		font-size: 16px;
		line-height: 28px;
	}

	/* Inner header */
	.inner-header {
		padding: 140px 0;
		.breadcrumb-title {
			font-size: 50px;
		}
	}

}

@media (max-width:767px) {

	.h1,
	h1 {
		font-size: 56px;
		line-height: 66px;
	}

	.h2,
	h2 {
		font-size: 28px;
		line-height: 38px;
	}

	.h3,
	h3 {
		font-size: 22px;
		line-height: 32px;
	}

	.h4,
	h4 {
		font-size: 18px;
		line-height: 28px;
	}

	.h5,
	h5 {
		font-size: 16px;
		line-height: 26px;
	}

	.h6,
	h6 {
		font-size: 15px;
		line-height: 26px;
	}

	/* Inner header */
	.inner-header {
		padding: 100px 0;
		.breadcrumb-title {
			font-size: 40px;
		}
	}


}

@media (max-width:575px) {

	.h1,
	h1 {
		font-size: 46px;
		line-height: 56px;
	}

	.h2,
	h2 {
		font-size: 24px;
		line-height: 34px;
	}

	.h3,
	h3 {
		font-size: 20px;
		line-height: 30px;
	}

	/* Inner header */
	.inner-header {
		padding: 50px 0;
		.breadcrumb {
			margin: 0 auto;
			text-align: center;
		}
		.breadcrumb-title {
			font-size: 30px;
		}
	}


	/* Inner header */
	.pagination {
		.page-item {
			.page-link {
			  padding: 18px 20px;
			}
		}
	}
}
