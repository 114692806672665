/*****************************
  counter
*****************************/

.counter {
	.counter-icon {
		.icon {
			padding-right: 30px;
			font-size: 80px;
			color: $primary;
		}
	}
	.counter-content {
		.timer {
			font-size: 48px;
			color: $gray-7;
			font-weight: 600;
			display: block;
			line-height: 48px;
			margin-bottom: 8px;
		}

	}

}
