/*****************************
  Gallery
*****************************/
.gallery-item {
  position: relative;
  overflow: hidden;
  text-align: center;

  &:before {
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    top: auto;
    background: rgba($gray-9, 0.85);
    content: "";
    transition: all 0.5s ease;
  }

  &:hover {
    &:before {
      bottom: 0;
      height: 100%;
    }

  }

  .gallery-title {
    display: block;
    color: $white;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;

    &:hover {
      color: $primary;
    }

  }

  .gallery-img {
    background-size: cover;
    min-height: 440px;
    background-position: center center;
  }

  .gallery-info {
    padding: 0 30px;
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 0px;
    z-index: 2;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: translate(-50%, 0%);
    background: transparent;

    p {
      overflow: auto;
      color: $white;
    }

    .icon-btn {
      width: 50px;
      height: 50px;
      line-height: 50px;
      background: $primary;
      color: $white;
      display: inline-block;
    }

    .icon-white {
      background: $white;
      color: $primary;
    }

  }

  &:hover {
    .gallery-info {
      opacity: 1;
      bottom: 40px;
    }

  }

}


.my-shuffle-container.grid-style {
  .grid-item {
    padding: 0 !important;
  }

}

.my-shuffle-container.columns-2 {
  .grid-item {
    width: 50%;
    padding: 15px;
    float: left;
  }

}

.my-shuffle-container.columns-3 {
  .grid-item {
    width: 33.33333333%;
    padding: 15px;
  }

}

.my-shuffle-container.columns-4 {
  .grid-item {
    width: 25%;
    padding: 10px;
  }

}

.my-shuffle-container.columns-5 {
  .grid-item {
    width: 20%;
    padding: 15px;
  }

}


.social-icon {
  display: flex;

  li {
    a {
      width: 50px;
      height: 50px;
      line-height: 56px;
      text-align: center;
      display: block;
      background: $gray-1;
      color: $gray-9;
      margin: 0 5px;
      font-size: 18px;

      &:hover {
        background: $primary;
        color: $white;
      }

    }

  }

}


@media (max-width:767px) {
  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item {
    width: 50%;
  }

}

@media (max-width:575px) {
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container .grid-item {
    width: 100%;
  }

  .my-shuffle-container .grid-item,
  .my-shuffle-container.columns-2 .grid-item,
  .my-shuffle-container.columns-3 .grid-item,
  .my-shuffle-container.columns-4 .grid-item,
  .my-shuffle-container.columns-5 .grid-item {
    width: 100%;
  }

}
