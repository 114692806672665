/*****************************
    Video
*****************************/

/* Video style 01 */
.video-style-01 {
    a {
        font-size: 36px;
        color: $white;   
        display: inline-block;
        position: relative;
        &:hover {
            color: $primary;
        }
        i {
            font-size: 48px;
            color: $primary;
            display: block;
            margin-top: 22px;
        }  
        &:hover {
            i {
                color: $white;
    		    transition: all 0.3s ease-in-out;
            }
        }       
    }  
    &:before {
        content: "";
        background-color: $gray-9;
        position: absolute;
        left: -50px;
        top: -50px;
        width: 170px;
        height: 212px;
    }      
}

/* Video style 02 */
.video-style-02 {
    a {
        position: absolute;
        top: 50%;
        left: 50%;
        color: $white;
        font-size: 70px;
        transform: translate(-50%, -50%);
        &:hover {
            color: $primary; 
        }
    }
}

/* Video style 03 */
.video-style-03 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-9;
    padding: 45px;
    min-height: 203px;
    a {
        color: $white;
        position: relative;
        font-size: 30px;
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 80px;
            height: 80px;
            background: rgba($white, 0.5);
            border-radius: 50%;
            animation: pulse-border 1500ms ease-out infinite;
        }
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 65px;
            height: 65px;
            background: rgba($white, 0.5);
            border-radius: 50%;
            animation: pulse-border 1700ms ease-out infinite;
        }
        i {
            position: relative;
            z-index: 1;
        }
    }
}


/* keyframes */
@keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
  
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  
  }

@media (max-width: 575px) {
/* Video style 02 */
.video-style-02  {
    a {
        font-size: 40px;
    }
}
    
}