/*****************************
    Pricing
*****************************/

/* Pricing style 01 */
.pricing-style-01 {
    padding-top: 70px;
    .discount {
        display: inline-block;
        text-transform: uppercase;
        color: $gray-7;
        font-weight: 700;
        margin-bottom: 5px;
    }
    h3 {
        margin-bottom: 20px;
    }
    .price-list {
        display: inline-block;
        li {
            font-size: 28px;
            padding: 0px 24px;
            font-weight: normal;
            opacity: 0.6;
            line-height: 48px;
            border-bottom: 1px solid rgba($gray-9, 0.1);
        }
    }
    .price {
        font-size: 28px;
        opacity: 0.6;
        font-weight: 600;
        margin: 25px 0px 45px 0px;
        span {
            font-size: 14px;
            font-weight: normal;
            margin-right: 5px;
        }  
    }
    .price-img {
        img {
            margin: 100px 0px -10px 0px;
        }
    }
}

@media (max-width: 757px) {
    /* Pricing style 01 */
    .pricing-style-01 {
        padding-top: 40px;
        .price-img {
            img {
                margin: 40px 0px -10px 0px;
            }
        }
    }
}