/*****************************
    Team
*****************************/

/* Team style 01 */
.team-style-01{
    text-align: center;
    position: relative;
    background-color: $white;
    &:hover {
        .team-img {
            z-index: 9;
            position: relative;
        }
        .team-info {
            z-index: 9;
            position: relative;
            ul {
                opacity: 1;
                bottom: -35px;
            }
        }
        .team-bg {
            opacity: 1;
            z-index: 5;
        }
    }
        .team-info {
        padding: 20px 0px 20px;
         h5{
            margin-bottom: 0;
          }
        .team-name {
            color: $gray-7;
            font-size: 20px;
            font-weight: 600;
            span {
               opacity: 0.6;
             }
            &:hover {
                color: $primary;
            }
        }
        ul {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            position: absolute;
            bottom: -25px;
            left: 0;
            right: 0;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            li {
                margin: 0 12px;
                a {
                    color: $gray-7;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    .team-bg {
        background: $white;
        position: absolute;
        left: -5%;
        top: -5%;
        width: 110%;
        height: 118%;
        z-index: 4;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        box-shadow: $box-shadow-lg;
    }
}