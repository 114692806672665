/*****************************
  Shuffle
*****************************/
.filters-group {
  text-align: center;
  .btn-filter {
    background-color: transparent;
    color: $gray-9;
    padding: 9px 28px;
    border: none;
    font-weight: 500;
    font-size: 17px;
    transition: all 0.3s ease-in-out;
  }
  .btn-filter.active {
    background-color: $gray-9;
    color: $primary;
    box-shadow: $box-shadow;
  }
}

/* filters style 02  */
.filters-group-style-02 {
  text-align: right;
  .btn-filter {
    background-color: transparent;
    color: $gray-9;
    padding: 0px;
    border: none;
    font-weight: 500;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    margin-right: 36px;
    &:last-child {
      margin-right: 0;
    }
  }
  .btn-filter.active {
    background-color: transparent;
    color: $primary;
    box-shadow: none;
  }
}

.my-shuffle-container{
  margin-left: -15px;
  margin-right: -15px;
  .grid-item {
    padding: 15px;
  }
}

.my-shuffle-container.columns-3 {
  .grid-item {
    width: 33.3333%;
  }
}

.my-shuffle-container.columns-4 {
  .grid-item {
    width: 25%;
  }
}

/*****************************
  Responsive
*****************************/

@media (max-width:1199px) {
  .my-shuffle-container.columns-4,
  .my-shuffle-container.columns-5 {
    .grid-item {
      width: 33.3333%;
    }
  }

  .filters-group-style-02 {
    text-align: left;
  }

}

@media (max-width:991px) {
  .my-shuffle-container.columns-4 {
    .grid-item {
      width: 50%;
    }
  }

  .filters-group-style-02 {
    .btn-filter {
      font-size: 18px;
    }
  }

}


@media (max-width:767px) {

  .my-shuffle-container.columns-3 {
    .grid-item {
      width: 50%;
    }
  }

  .filters-group-style-02 {
    .btn-filter {
      font-size: 16px;
      margin-right: 20px;
    }
  }

  .gallery-item {
    .gallery-info {
      padding: 0 15px;
    }
  } 

}

@media (max-width:575px) {

  .my-shuffle-container.columns-3 {
    .grid-item {
      width: 100%;
    }
  }

  .my-shuffle-container.columns-4 {
    .grid-item {
      width: 100%;
    }
  }

}
