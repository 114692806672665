/*****************************
    Testimonial
*****************************/

/* Testimonial style 01 */
.testimonial{
    .testimonial-item {
        padding: 20px 200px 0;
        position: relative;
        text-align: center;
        z-index: 1;
        .testimonial-content{
            margin-bottom: 60px;
            p {
                font-weight: 500;
                font-size: 28px;
                line-height: 40px;
            }
        }
        .testimonial-author {
            margin-bottom: 20px;
        }
        .testimonial-author-img {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            width: 83px;
            height: 83px;
            margin: 0px auto 60px auto;
            border-radius: $border-radius;
            box-shadow: $box-shadow-lg;
            img {
                height: 42px;
            }
        }
        .testimonial-quote {
            position: absolute;
            left: 8%;
            font-size: 80px;
            opacity: 0.1;
            top: 0;
        }
    }
}

.testimonial.owl-carousel {
    .owl-nav {
        display: flex;
        justify-content: space-between;
        width: auto;
        display: inline-block;
        position: relative;
        padding: 0px;
        background: $white;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        i {
            font-size: 28px;
            color: $gray-9;
            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.testimonial-small {
    .testimonial-item {
        padding: 0;
        text-align: left;

        .testimonial-content {
              margin-bottom: 10px;
            p {
                font-size: 16px;
                line-height: 22px;
            }

        }

    }

}

/* Testimonial style 02 */
.testimonial-style-02 {
    .testimonial-item {
        text-align: left;
        padding: 0;
        .testimonial-quote {
            left: 40%;
        }
        .testimonial-author-img {
            margin: 0 0 60px;
        }
        .testimonial-content {
            margin: 0px 150px 60px 0;
            p {
                font-size: 19px;
                line-height: 36px;
                margin-bottom: 0px;
            }
        }
    }

}

/* Testimonial style 03 */
.testimonial-style-03 {
    .testimonial-item {
        text-align: left;
        padding: 0;
        .testimonial-quote {
            left: 40%;
        }
        .testimonial-author-img {
            margin: 0 0 60px;
        }
        .testimonial-content {
            margin: 0px 80px 60px 0;
            p {
                font-size: 19px;
                line-height: 36px;
                margin-bottom: 0px;
            }
        }
    }

}


.owl-carousel.testimonial-style-02 {
    .owl-nav {
        .owl-prev {
            left: -40px;
        }
        .owl-next {
            right: 40px;
        }
    }
}



@media (max-width: 1599px) {
    .testimonial.owl-carousel.testimonial-style-02 {
        .testimonial-item {
            .testimonial-content {
                margin: 0px 150px 30px 0;
            }
        }
        .owl-nav {
            top: inherit;
            bottom: -10px;
            .owl-prev {
                left: 0px;
            }
            .owl-next {
                right: 0;
                left: 40px;
            }
        }
    }

}


@media (max-width: 1199px) {
    .testimonial {
        .testimonial-item {
            padding: 20px 100px 0;
        }
    }

    .testimonial.owl-carousel.testimonial-style-02 {
        .testimonial-item {
            padding: 0;
            .testimonial-content {
                margin: 0px 30px 30px 0;
            }
        }
    }

    .testimonial.testimonial-style-03 {
     .testimonial-item{
        padding: 20px 20px 0;
        .testimonial-content {
          margin: 0px 30px 30px 0;
        }
      }
    }

}


@media (max-width: 767px) {
    .testimonial {
        .testimonial-item {
            padding: 20px 40px 0;
            .testimonial-quote {
                font-size: 60px;
            }
            .testimonial-author-img {
                margin: 0px auto 30px auto;
            }
            .testimonial-content {
                margin-bottom: 30px;
            }
        }
    }

    .testimonial.owl-carousel.testimonial-style-02 {
        .testimonial-item {
            .testimonial-author-img {
                margin: 0px 0 30px 0;
            }
        }
    }

}


@media (max-width: 575px) {
    .testimonial {
        .testimonial-item {
            padding: 20px 0px 0;
            .testimonial-content {
                h3 {
                    line-height: 30px;
                }
                p{
                    font-size:22px;
                    line-height: 32px !important;
                 }
            }
            .testimonial-quote {
                font-size: 40px;
            }
        }
    }

    .testimonial.owl-carousel {
        .owl-nav {
            display: none;
        }
    }

    .testimonial.owl-carousel.testimonial-style-02 {
        .testimonial-item {
            .testimonial-content {
                margin: 0px 0px 10px 0;
                p {
                    font-size: 16px;
                    line-height: 30px;
                }
            }
        }
    }

}
