/*****************************
	Progress Bar
*****************************/
/* Progress Bar Style 1 */
.progress-style-01 {
	.progress-item {
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0px;
		}
		.progress-title {
			color: $gray-9;
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 10px;
		}
		.progress {
			overflow: inherit;
			background-color: $gray-1;
			position: relative;
			border-radius: 0px;
			span {
				color: $gray-9;
				font-weight: 700;
				font-size: 14px;
				line-height: 1;
				position: absolute;
				right: 0;
				bottom: 15px;
			}
			.progress-bar {
				overflow: inherit;
				background-color: $primary;

			}
		}
	}
}