/*****************************
  Shortcodes
*****************************/

/*****************************
  Accordion
*****************************/

.card {
	border: none;
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0;
	}
}
.card-header {
	background: none;
	padding: 0;
	border-bottom: none;
}
.accordion {
	>.card {
		.card-header {
			margin-bottom: 0;
			.btn {
				text-align: left;
				font-size: 16px;
				line-height: 24px;
				padding: 11px 45px 11px 26px;
				font-weight: bold;
				display: inline-block;
				width: 100%;
				background: $primary;
				color: $white;
				&:after {
					font-family: "Font Awesome 5 Free";
					position: absolute;
					top: 12px;
					right: 25px;
					transition: all 0.3s ease-in-out;
					content: "\f068";

				}
			}
			.btn.collapsed {
				background: none;
				color: $gray-7;
				&:after {
					content: "\f067";
				}
			}
		}
	}
	.accordion-title {
		background-color: $gray-1;
	}
}
.card-body {
	padding: 20px 26px;
}


